import Vimeo from '@vimeo/player';
import { getCookie, setCookie } from './helpers.js'

function filterVideos(category) {
  $('.js-video-lesson').hide();
  $('.js-video-lesson[data-category='+category+']').show();
}

$(document).on('ready turbolinks:load', function() {
  if ($('.js-category-filters').length) {
    // Select remembered video lesson category
    var category = getCookie('video-lessons-category');
    if (!category) {
      category = $('.js-category-filters button').first().data('category');
      setCookie('video-lessons-category', category);
    }
    $('.js-category-filters button[data-category='+category+']').addClass('active');

    filterVideos(category);

    // Handle selection of video lesson category
    $('.js-category-filters button').click(function(event) {
      var btn = $(event.target);
      var category = btn.data('category');
      setCookie('video-lessons-category', category);

      $('.js-category-filters button').removeClass('active');
      btn.addClass('active');

      filterVideos(category);
    })
  }

  // Add videoplayer
  $('.video-player').each(function(i, player_div) {
    var videoPlayer = new Vimeo(player_div);
    var videoLessonId = $(player_div).data('video-lesson-id');
    var videoDuration, timer;

    if (videoLessonId) {
      var logPercentagePlayed = function(percentagePlayed) {
        $.post(
          '/video_lessons/'+videoLessonId+'/views',
          {'percentage_played': percentagePlayed}
        );
        $('#progress-bar').css('width', Math.round(percentagePlayed)+'%');
        $('#percentage_watched').html(Math.round(percentagePlayed));
      }
      var checkPercentagePlayed = function() {
        videoPlayer.getCurrentTime().then(function(seconds) {
          if (!videoDuration) { return;}
          logPercentagePlayed(seconds / videoDuration * 100);
        });
      }

      videoPlayer.getDuration().then(function(duration) { videoDuration = duration });

      videoPlayer.on('play', function(data) {
        logPercentagePlayed(data.percent*100);
        timer = setInterval(checkPercentagePlayed, 5000 /* ms */);
      });
      videoPlayer.on('pause', function(data) {
        logPercentagePlayed(data.percent*100);
        clearInterval(timer);
      });
      videoPlayer.on('ended', function(data) {
        logPercentagePlayed(data.percent*100);
        clearInterval(timer);
      });
    }
  })
});
